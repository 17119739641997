import request from "@/utils/request.js";

// 门店查询抖音核销订单
export const getDouyinGoodsOrder = (params) => {
  return request({
    method: "get",
    url: "trilateral/douyin/goods/order/store_page",
    params,
  });
};
// 抖音商品列表
export const getDouyinShopProduct = () => {
  return request({
    method: "get",
    url: "trilateral/douyin_shop_product/list",
  });
};
