<template>
  <div class="tiktokCertificate-container content-index">
    <el-page-header @back="goBack" content=""> </el-page-header>
    <div class="search">
      <Search :search-data="searchData" @searchChange="searchChange" />
    </div>
    <div class="table-box">
      <Table
        :button-type="false"
        :button-width="150"
        :current-value="pageData.current"
        :header-style="headerStyle"
        :height="''"
        :loading="tableLoading"
        :orientation="'center'"
        :serial-number="true"
        :table-data="tableData"
        :title-list="titleListData"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Search from "@/components/search";
import Table from "@/components/table2";
import { getDouyinGoodsOrder, getDouyinShopProduct } from "@/api/tiktok/index";
export default {
  name: "",
  components: {
    Search,
    Table,
  },
  data() {
    return {
      searchData: [
        {
          prop: "time",
          label: "核销时间",
          type: "DateTime",
          labelWidth: "80px",
          width:240
        },
        {
          prop: "skuId",
          label: "商品名称",
          type: "select",
          selectArr: [],
          labelWidth: "80px",
          width:200
        },
        {
          prop: "orderNumber",
          label: "精准查询",
          type: "text",
          labelWidth: "80px",
          width:200
        },
        {
          typeList: [
            {
              name: "查询",
              code: "search",
              type: "primary",
            },
            {
              name: "重置",
              code: "reset",
              type: "primary",
            },
          ],
          labelWidth: "20px",
        },
      ],
      pageData: {
        current: 1,
        size: 10,
      },
      tableData: [],
      titleListData: [
        {
          prop: "orderNumber",
          label: "订单号",
          width: "150px",
        },
        {
          prop: "code",
          label: "券码",
          width: "150px",
        },
        {
          prop: "result",
          label: "券码状态",
          width: "100px",
        },
        {
          prop: "paySuccTime",
          label: "核销时间",
          width: "150px",
        },
        {
          prop: "storeName",
          label: "核销门店",
          width: "100px",
        },
        {
          prop: "skuName",
          label: "商品名称",
          width: "150px",
        },
        {
          prop: "productTypeName",
          label: "商品类型",
          width: "100px",
        },
        {
          prop: "payAmount",
          label: "订单实收(元)",
          width: "100px",
        },
        {
          prop: "storeUserName",
          label: "核销人",
          width: "100px",
        },
      ],
      total: 0,
      headerStyle: {
        background: "#FAFAFA",
      },
      tableLoading: false,
    };
  },
  created() {
    this.getDouyinShopProductList();
    this.getDouyinGoodsOrderList();
  },
  methods: {
     goBack() {
        this.$router.back()
      },
    // 获取抖音商品列表
    getDouyinShopProductList() {
      getDouyinShopProduct().then(({ data }) => {
        if (data.code == 0) {
          this.searchData[1].selectArr = data.data.map((item) => {
            return {
              id: item.skuId,
              name: item.skuName,
            };
          });
        }
      });
    },
    // 获取表格数据
    getDouyinGoodsOrderList() {
      this.tableLoading = true;
      getDouyinGoodsOrder({ ...this.pageData })
        .then(({ data }) => {
          if (data.code == 0) {
            this.tableData = data.data.records
            this.total = data.data.total;
            this.tableLoading = false;
          } else {
            this.tableLoading = false;
            this.$message.error(data.msg);
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    // 搜索触发
    searchChange({ code, formData }) {
      if (code == "reset") {
        this.pageData = {
          current: 1,
          size: 10,
        };
      } else {
        this.pageData = {
          ...this.pageData,
          current:1,
          orderNumber: formData.orderNumber,
          skuId: formData.skuId,
          endTime:
            formData.time && formData.time.length != 0 ? formData.time[1] : "",
          beginTime:
            formData.time && formData.time.length != 0 ? formData.time[0] : "",
        };
      }
      this.getDouyinGoodsOrderList();
    },
    // 切换页码
    handleCurrentChange(value) {
      this.pageData.current = value;
      this.getDouyinGoodsOrderList();
    },
  },
};
</script>

<style lang="scss" scoped>
.tiktokCertificate-container {
  padding: 20px;
  overflow: auto;
  .search {
    margin: 10px 0;
  }
}
::v-deep .el-table__body td.el-table__cell {
  height: 50px;
}
::v-deep .el-page-header__title{
  font-size: 18px;
  padding-left: 10px;
}
::v-deep .el-page-header__left::after{
  width:0
}
::v-deep .el-page-header__left .el-icon-back{
  font-size: 20px;
}
</style>
